import { useState, useCallback, useRef } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import { DEFAULT_SCALE, DEFAULT_SCALE_MIN, DEFAULT_SCALE_MAX, DEFAULT_SCALE_STEP } from '../../tools/defaults';
import { useResizeObserver } from '../../tools/hooks';
import { useOrderScaling } from './Context';
import OrderScalingCurveChart from './CurveChart';

export default function OrderScalingCurve() {
  const { scale } = useOrderScaling();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const onResize = useCallback((target) => {
    setTimeout(() => {
      setDimensions(target.getBoundingClientRect());
    }, 1000);
  }, []);
  const ref = useResizeObserver(onResize);
  const refSlider = useRef();

  return (
    <Grid container spacing={1} sx={{ px: 2, py: 1, minHeight: 200 }}>
      <Grid item xs={12}>
        <Typography variant="caption">Scaling Curve</Typography>
      </Grid>
      <Grid ref={ref} item xs={12} sx={{ display: 'flex', mb: 1, width: '100%', justifyContent: 'space-between' }}>
        {dimensions.width === 0 && <Skeleton variant="rounded" width="100%" height={248} />}
        {dimensions.width > 0 && (
          <OrderScalingCurveChart
            width={dimensions.width - (refSlider.current?.getBoundingClientRect()?.width || 0) - 10}
            height={200}
          />
        )}
        <Box ref={refSlider} sx={{ pt: 1, pb: 2, pl: 2 }}>
          <Slider
            size="small"
            min={DEFAULT_SCALE_MIN}
            max={DEFAULT_SCALE_MAX}
            step={DEFAULT_SCALE_STEP}
            value={scale.value}
            onChange={(_, v) => scale.mutate(v)}
            valueLabelDisplay="auto"
            orientation="vertical"
            valueLabelFormat={(v) => parseFloat(v).toFixed(2)}
            onDoubleClick={() => scale.mutate(DEFAULT_SCALE)}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
