import { useState, useEffect, Fragment } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import { exchange } from '../../chart/exchange';
import { useTradingView } from './Context';

export default function TradingViewSymbols() {
  const { isLoadedFromStore, setMarket, setReady, market } = useTradingView();
  const [openSymbolPicker, setOpenSymbolPicker] = useState(false);
  const [markets, setMarkets] = useState([]);
  const loading = markets.length === 0;

  useEffect(() => {
    (async () => {
      if (isLoadedFromStore) {
        const loadedMarkets = await exchange.fetchMarkets();
        const uniqueMarkets = [...new Map(loadedMarkets.map((v) => [v.symbol, v])).values()];
        const currentMarket = uniqueMarkets.find((v) => v.symbol === market.value.symbol);
        setMarkets(uniqueMarkets);
        setMarket(currentMarket);
        setReady(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadedFromStore, setReady, setMarket,]);

  return (
    <Autocomplete
      open={openSymbolPicker}
      onOpen={() => setOpenSymbolPicker(true)}
      onClose={() => setOpenSymbolPicker(false)}
      isOptionEqualToValue={(option, value) => option.symbol === value.symbol}
      getOptionLabel={(option) => `${option.id} (${option.type})`}
      options={markets}
      loading={loading}
      size="small"
      value={market.value}
      onChange={(_, value) => market.mutate(value)}
      disableClearable
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
      sx={{ minWidth: 200, bgcolor: '#0E1018', display: 'inline-flex' }}
    />
  );
}
