import { useRef } from 'react';
import { Panel, PanelGroup } from 'react-resizable-panels';
import useMediaQuery from '@mui/material/useMediaQuery';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import { DEFAULT_MIN_LEFT_PANEL, DEFAULT_MIN_RIGHT_PANEL } from '../tools/defaults';

import TradingViewLWC from './TradingView/LWC';
import TradingViewControls from './TradingView/Controls';
import OrderStrategyCard from './OrderStrategy/Card';
import OrderStrategySwitcher from './OrderStrategy/Switcher';
import WalletCard from './Wallet/Card';
import ResizeHandle from './ResizeHandle';
import DonationBar from './Donation/Bar';

function App() {
  const refPanel = useRef();

  const smallBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const panelGroupComponent = smallBreakpoint ? PanelGroup : 'div';
  const panelGroupProps = smallBreakpoint ? { autoSaveId: 'autosavePanelLeft', direction: 'horizontal' } : {};
  const panelComponent = smallBreakpoint ? Panel : 'div';
  const panelRightProps = smallBreakpoint ? { minSize: 15, defaultSize: 25 } : {};
  const panelLeftProps = smallBreakpoint ? { minSize: DEFAULT_MIN_RIGHT_PANEL } : {};

  return (
    <Box
      className="App"
      sx={{
        padding: 2,
        maxHeight: smallBreakpoint ? '100vh' : 'inherit',
        overflow: smallBreakpoint ? 'hidden' : 'inherit',
      }}
    >
      <Box component={panelGroupComponent} id="panelApp" {...panelGroupProps}>
        {!smallBreakpoint && <DonationBar sx={{ mb: 2 }} />}
        <Box ref={refPanel} component={panelComponent} {...panelRightProps}>
          <Box sx={{ overflow: 'auto' }}>
            <Grid container spacing={2} sx={{ overflow: 'auto', maxHeight: smallBreakpoint ? '100vh' : 'inherit' }}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <WalletCard />
                  </Grid>
                  <Grid item xs={7}>
                    <OrderStrategyCard />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <OrderStrategySwitcher />
              </Grid>
            </Grid>
          </Box>
        </Box>
        {smallBreakpoint && (
          <ResizeHandle
            onDoubleClick={() => {
              const shouldCollapse = refPanel.current.getSize() !== DEFAULT_MIN_LEFT_PANEL;
              const size = shouldCollapse ? DEFAULT_MIN_LEFT_PANEL : 100 - DEFAULT_MIN_RIGHT_PANEL;
              refPanel.current.resize(size);
            }}
            sx={{ height: (theme) => `calc(100vh - ${theme.spacing(4)})` }}
          />
        )}
        <Box component={panelComponent} {...panelLeftProps} sx={{ position: 'relative' }}>
          <TradingViewControls />
          <TradingViewLWC />
          {smallBreakpoint && <DonationBar sx={{ mt: 1 }} />}
        </Box>
      </Box>
    </Box>
  );
}

export default App;
