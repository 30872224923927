import { useState, useEffect, useContext, createContext } from 'react';
import localforage from 'localforage';

import { DEFAULT_MARKET, DEFAULT_RESOLUTION } from '../../tools/defaults';
import { wrap } from '../../tools/context';

export const STORAGE_KEY = 'TradingView';
export const TradingView = createContext();
export const useTradingView = () => useContext(TradingView);
export function TradingViewProvider({ children }) {
  const [isReady, setReady] = useState(false);
  const [isLoadedFromStore, setLoadedFromStore] = useState(false);
  const [market, setMarket] = useState(DEFAULT_MARKET);
  const [resolution, setResolution] = useState(DEFAULT_RESOLUTION);

  useEffect(() => {
    // <<< LOAD >>>
    (async () => {
      const { market, resolution } = (await localforage.getItem(STORAGE_KEY)) || {};
      setMarket(market || DEFAULT_MARKET);
      setResolution(resolution || DEFAULT_RESOLUTION);
      setLoadedFromStore(true);
    })();
  }, []);

  useEffect(() => {
    // <<< UPDATE >>>
    (async () => {
      await localforage.setItem(STORAGE_KEY, { market, resolution });
    })();
  }, [market, resolution]);

  const provider = {
    ...{ isLoadedFromStore, setLoadedFromStore },
    ...{ setReady },
    ...{ setMarket },
    isReady: wrap(isReady, setReady),
    market: wrap(market, setMarket),
    resolution: wrap(resolution, setResolution),
  };
  return <TradingView.Provider value={provider}>{children}</TradingView.Provider>;
}

export const withTradingView = (children) => <TradingViewProvider>{children}</TradingViewProvider>;
