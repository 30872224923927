// Evidence Negative/Positive number sign.
export const numsign = (num) => (parseFloat(num) > 0 ? `+${num}` : `${num}`);

// Non-Negative.
export const nn = (num) => (num < 0 ? 0 : num);

// Minimun Move for a given precision.
export const minmove = (precision = 0, tick = '1') => {
  const zeros = '0'.repeat(Math.max(precision - 1, 0));
  return precision < 1 ? 0 : parseFloat(`0.${zeros}${tick}`);
};

// Division `divisor` for a given precision.
// e.g.
// > precision=3 (0.001)
// > divisor=1000
// > 1234 ÷ divisor = 1.234
export const divisor = (precision = 0) => {
  const zeros = '0'.repeat(Math.max(precision, 0));
  return precision < 1 ? 1 : parseFloat(`1${zeros}`);
};

// Check if is finite number.
export const isfinite = num => !isNaN(num) && isFinite(num);
