import { useState, useEffect } from 'react';
import { divide, plus, times } from 'number-precision';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Grow from '@mui/material/Grow';

import { numsign, isfinite } from '../../tools/number';
import { leverageLevelColor } from '../../tools/order';
import { changePercent } from '../../tools/calculations';
import { useOrderScaling } from './Context';
import ButtonBar from '../ButtonBar';
import TextFieldNumber from '../TextFieldNumber';
import { useTradingView } from '../TradingView/Context';

const ORDER_QTY_MIN = 2;
const ORDER_QTY_MAX = 20;

export default function OrderScalingForm() {
  const { market } = useTradingView();
  const orderScaling = useOrderScaling();
  const { isOrderScalingLoaded } = orderScaling;
  const { isWrongRisk, isWrongStopLoss, isWrongLastPrice, isWrongLeverage } = orderScaling;
  const { risk, orderQty, type, firstPrice, lastPrice, stopLoss, takeProfit } = orderScaling;
  const { leverageColor, leverage, calculations, resetExecutedOrders } = orderScaling;

  const [orderQtyTemp, setOrderQtyTemp] = useState();
  const handleOrderQty = (value) => {
    const minMax = Math.max(ORDER_QTY_MIN, Math.min(ORDER_QTY_MAX, parseInt(value)));
    orderQty.mutate(minMax);
    setOrderQtyTemp(minMax);
    resetExecutedOrders();
  };
  useEffect(() => {
    if (orderQty.value) setOrderQtyTemp(orderQty.value);
  }, [orderQty.value]);

  const selectOnFocus = ({ target }) => target.select();

  const stopLossChangePercent = numsign(
    changePercent(calculations.positionWeightedAvg || 0, stopLoss.value).toFixed(2)
  );
  const takeProfitChangePercent = numsign(
    changePercent(calculations.positionWeightedAvg || 0, takeProfit.value).toFixed(2)
  );

  return (
    <Grow in={isOrderScalingLoaded} timeout="auto" unmountOnExit>
      <Grid container spacing={2} sx={{ mb: 1 }}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                variant={type.value === 'long' ? 'contained' : 'outlined'}
                color="success"
                onClick={() => {
                  type.mutate('long');
                  resetExecutedOrders();
                }}
                fullWidth
              >
                Long
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant={type.value === 'short' ? 'contained' : 'outlined'}
                color="error"
                onClick={() => {
                  type.mutate('short');
                  resetExecutedOrders();
                }}
                fullWidth
              >
                Short
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                value={orderQtyTemp || ''}
                type="number"
                label="Orders"
                size="small"
                fullWidth
                inputProps={{ style: { textAlign: 'center' } }}
                onBlurCapture={({ target: { value } }) => {
                  if (value) handleOrderQty(value);
                  else setOrderQtyTemp(orderQty.value);
                }}
                onKeyDown={({ key, target: { value } }) => {
                  if (key === 'Enter' && value) handleOrderQty(value);
                }}
                onFocusCapture={selectOnFocus}
                onChange={({ target: { value } }) => setOrderQtyTemp(value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldNumber
                value={leverage.value}
                precision={0}
                onValueChange={(value) => {
                  leverage.mutate(value);
                  leverageColor.mutate(leverageLevelColor(value));
                }}
                max={125}
                label="Leverage"
                size="small"
                error={isWrongLeverage}
                color={leverageColor.value}
                onFocusCapture={selectOnFocus}
                inputProps={{ style: { textAlign: 'center' } }}
                InputProps={{ endAdornment: <InputAdornment position="end">x</InputAdornment> }}
                fullWidth
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldNumber
                value={risk.value}
                precision={2}
                thousandsSeparator=" "
                onValueChange={(value) => risk.mutate(value)}
                label="Risk"
                size="small"
                error={isWrongRisk}
                onFocusCapture={selectOnFocus}
                inputProps={{ step: '0.1', style: { textAlign: 'center' } }}
                InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextFieldNumber
                value={firstPrice.value}
                precision={market.value.precision.price}
                thousandsSeparator=" "
                onValueChange={(value) => {
                  firstPrice.mutate(value);
                  resetExecutedOrders();
                }}
                label="First Order"
                size="small"
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                fullWidth
              />
            </Grid>
            <Grid item xs={6}>
              <TextFieldNumber
                value={lastPrice.value}
                precision={market.value.precision.price}
                thousandsSeparator=" "
                onValueChange={(value) => {
                  lastPrice.mutate(value);
                  resetExecutedOrders();
                }}
                label="Last Order"
                size="small"
                error={isWrongLastPrice}
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextFieldNumber
                value={stopLoss.value}
                precision={market.value.precision.price}
                thousandsSeparator=" "
                onValueChange={(value) => stopLoss.mutate(parseFloat(value))}
                label="Stop-loss"
                size="small"
                fullWidth
                error={isWrongStopLoss || !isfinite(takeProfitChangePercent)}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  endAdornment: isfinite(stopLossChangePercent) && (
                    <InputAdornment position="end" sx={{ fontSize: 12 }}>
                      ({stopLossChangePercent}%)
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldNumber
                value={takeProfit.value}
                precision={market.value.precision.price}
                thousandsSeparator=" "
                onValueChange={(value) => takeProfit.mutate(parseFloat(value))}
                label="Take-profit"
                size="small"
                fullWidth
                error={!isfinite(takeProfitChangePercent) || takeProfitChangePercent < 0}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  endAdornment: isfinite(takeProfitChangePercent) && (
                    <InputAdornment position="end" sx={{ fontSize: 12 }}>
                      ({takeProfitChangePercent}%)
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ mt: -1 }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <ButtonBar
                name="stoploss"
                onClick={(value) => {
                  const percentPrice = times(calculations.positionWeightedAvg, divide(parseInt(value, 10), 100));
                  stopLoss.mutate(plus(stopLoss.value, percentPrice) || 0);
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <ButtonBar
                name="takeprofit"
                onClick={(value) => {
                  const percentPrice = times(calculations.positionWeightedAvg, divide(parseInt(value, 10), 100));
                  takeProfit.mutate(plus(takeProfit.value, percentPrice) || 0);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grow>
  );
}
