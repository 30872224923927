import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function ButtonBar({ name, onClick = () => {}, options = ['-5', '-1', '+1', '+5'] }) {
  const colorStrategy = (value) => (theme) => {
    const hex = parseInt(value) > 0 ? theme.palette.green : theme.palette.red;
    const alpha = 15;
    return `${hex}${alpha}`;
  };
  return (
    <ToggleButtonGroup size="small" fullWidth>
      {options.map((value, i) => (
        <ToggleButton
          key={`${name}-${value}`}
          value={value}
          onClick={(_, value) => onClick(value)}
          sx={{ p: 0, backgroundColor: colorStrategy(value) }}
        >
          {value}%
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
