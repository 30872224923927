import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { noop } from '../../tools/void';
import CopyFeedback from '../CopyFeedback';

function CodeQR({ width, qrcode, title, onClick, disabled = false, sx = {} }) {
  return (
    <Box
      sx={{
        display: 'inline-block',
        p: 2,
        bgcolor: 'white',
        borderRadius: 2,
        opacity: disabled ? 0.3 : 1,
        cursor: disabled ? 'default' : 'zoom-in',
        ...sx,
      }}
      onClick={onClick}
    >
      <img src={qrcode} alt={title} style={{ width, marginBottom: -6, pointerEvents: 'none' }} />
    </Box>
  );
}

export default function DonationCard({
  title,
  description,
  footer,
  footerButtonLabel,
  footerButtonCopy,
  externalLink,
  qrcode,
  disabled = false,
}) {
  const smallBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const [zoom, setZoom] = useState(false);
  const propsQRCode = { qrcode, title, onClick: disabled ? noop : () => setZoom(true), disabled };

  return (
    <>
      <Card sx={{ borderRadius: 2, pb: 3, pt: 2 }}>
        <CardContent sx={{ textAlign: 'center' }}>
          <Typography variant="h5" sx={{ mb: 3 }} gutterBottom>
            {title}
          </Typography>
          <Typography>{description}</Typography>
          <CodeQR {...propsQRCode} width={144} sx={{ my: 3 }} />
          {footer && <Typography>{footer}</Typography>}
          {(footerButtonLabel || footerButtonCopy) && (
            <Typography>
              {(footerButtonLabel || footerButtonCopy) && (
                <CopyFeedback
                  component={Button}
                  variant="contained"
                  size="small"
                  color="secondary"
                  copyText={footerButtonCopy}
                  sx={{ verticalAlign: 'middle', fontSize: 14 }}
                >
                  {footerButtonLabel}
                  <CopyFeedback.Icon sx={{ fontSize: 12, ml: 1 }} />
                </CopyFeedback>
              )}
            </Typography>
          )}
          {externalLink && (
            <Button variant="contained" color="secondary" onClick={() => window.open(externalLink, '_blank')}>
              Open <OpenInNewIcon sx={{ fontSize: 16 }} />
            </Button>
          )}
        </CardContent>
      </Card>
      <Modal
        open={zoom}
        onClose={() => setZoom(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{ backdrop: { timeout: 500 } }}
        disableAutoFocus
        keepMounted
      >
        <Fade in={zoom}>
          <Box
            sx={{
              position: 'absolute', //
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              boxShadow: 24,
              width: (theme) => (smallBreakpoint ? 'inherit' : `calc(100% - ${theme.spacing(8)})`),
            }}
          >
            <CodeQR {...propsQRCode} width={smallBreakpoint ? 500 : '100%'} />
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
