import { Fragment } from 'react';
import { plus, divide, minus } from 'number-precision';
import { extent, min, max } from 'd3-array';
import { Group } from '@visx/group';
import { LinePath } from '@visx/shape';
import { scaleLinear } from '@visx/scale';
import { Text } from '@visx/text';
import { Grid } from '@visx/grid';
import { curveNatural } from '@visx/curve';

import * as calc from '../../tools/calculations';
import { nn } from '../../tools/number';
import { calculateOrderScalingPrices, calculateOrderScalingSizes } from '../../tools/order';
import { useOrderScaling } from './Context';
import { useTradingView } from '../TradingView/Context';

const k = (num, precision = 2) => {
  return num > 1000 || num < -1000 ? `${divide(num, 1000).toFixed(precision)}k` : num.toFixed(precision);
};

export default function OrderScalingCurve({ width: w, height }) {
  const { market } = useTradingView();
  const { hasWrongValues, scale, orderQty, firstPrice, lastPrice, calculations } = useOrderScaling();

  const width = nn(w);

  // data accessors
  const getPrice = (d) => d[0];
  const getSize = (d) => d[1];
  const getSizeCumm = (d) => d[2];

  // data transformers
  const cummSize = (acc, i) => plus(sizes[i], getSize(acc?.[i - 1] || []) || 0);

  // data
  const { precision } = market.value;
  const opts = { preventTrim: true, precision };
  const prices = calculateOrderScalingPrices(orderQty.value, firstPrice.value, lastPrice.value, opts);
  const sizes = calculateOrderScalingSizes(orderQty.value, calculations.positionSize, scale.value, opts);
  const seriesAll = [prices.reduce((acc, price, i) => [...acc, [-price, cummSize(acc, i), sizes[i]]], [])];
  const series = [seriesAll[0].map((s) => [getPrice(s), getSizeCumm(s)])];
  const data = seriesAll.reduce((rec, d) => rec.concat(d), []);

  // scales
  const preventFlatDomain = (domain) => {
    const offsetDomain = [minus(domain[0], domain[0]), plus(domain[1], domain[1])];
    return domain.every((v) => v === domain[0]) ? offsetDomain : domain;
  };
  const domainX = preventFlatDomain(extent(data, getPrice));
  const domainY = preventFlatDomain(extent(data, getSize));
  const scaleX = scaleLinear({ domain: domainX });
  const scaleY = scaleLinear({ domain: domainY });

  // coords accessors
  const x = (d) => scaleX(getPrice(d));
  const y = (d) => scaleY(getSize(d));

  // calcs
  const avg = calc.wavg(...series[0].reduce((a, cur) => [...a, ...cur], []));
  const avgLine = [
    [avg, min(domainY)],
    [avg, max(domainY)],
  ];

  // update scale output ranges
  const padding = 24;
  scaleX.range([0, width - padding * 2]);
  scaleY.range([height - 8, 0]);

  const calculationFormula = {
    [scale.value !== 0]: `Exponential f(x) = x ^ (1 + ${scale.value.toFixed(2)})`,
    [scale.value === 0]: 'Linear f(x) = x',
    [hasWrongValues]: 'Invalid Data',
  }[true];

  return (
    <svg width={width} height={height + padding * 2}>
      <rect width={width} height={height + padding * 2} fill="#1A1C28" rx={10} ry={10} />
      {seriesAll.map((lineData, i) => {
        return (
          <Group key={`lines-${i}`} top={padding} left={padding}>
            <Grid
              xScale={scaleX}
              yScale={scaleY}
              width={width - padding * 2}
              height={height}
              numTicksRows={3}
              numTicksColumns={orderQty.value}
              stroke="rgba(255, 255, 255, 0.1)"
            />
            <Text fill="white" textAnchor="start" fontSize={12} x={0} y={0}>
              {calculationFormula}
            </Text>
            {!hasWrongValues && (
              <>
                <LinePath
                  curve={curveNatural}
                  data={lineData}
                  x={x}
                  y={y}
                  stroke="#ffffff"
                  strokeWidth={1}
                  strokeOpacity={1}
                />
                {lineData.map((d, j) => (
                  <Fragment key={`line-${i}-${j}`}>
                    <Text fill="white" textAnchor="middle" x={x(d)} y={y(d)} dx={0} dy={20} fontSize={10} fontWeight={900}>
                      {`${k(Math.abs(getPrice(d)), precision.price)}`}
                    </Text>
                    <circle r={4} cx={x(d)} cy={y(d)} stroke="#1A1C28" strokeWidth={3} fill="rgba(255, 255, 255, 1)" />
                  </Fragment>
                ))}
                {/* AVG Line */}
                <LinePath
                  data={avgLine}
                  x={x}
                  y={y}
                  stroke="yellow"
                  strokeWidth={1}
                  shapeRendering="geometricPrecision"
                  strokeLinecap="butt"
                  strokeLinejoin="round"
                />
                {!isNaN(avg) && (
                  <Text fill="yellow" textAnchor="middle" fontSize={10} x={x(avgLine[0])} y={y(avgLine[0])} dy={12}>
                    {`AVG ${k(Math.abs(avg), precision.price)}`}
                  </Text>
                )}
              </>
            )}
          </Group>
        );
      })}
    </svg>
  );
}
