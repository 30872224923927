import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { enableBoundaryChecking } from 'number-precision';
import localforage from 'localforage';

import theme from './theme';
// import reportWebVitals from './cra/reportWebVitals';
import { compose } from './tools/react';
import { SnackbarProvider } from './components/Snackbar';
import { OrderStrategyProvider } from './components/OrderStrategy/Context';
import { OrderScalingProvider } from './components/OrderScaling/Context';
import { SingleOrderProvider } from './components/SingleOrder/Context';
import { TradingViewProvider } from './components/TradingView/Context';
import { WalletProvider } from './components/Wallet/Context';
import App from './components/App';

import './index.css';

enableBoundaryChecking(false);

localforage.config({
  driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE, localforage.WEBSQL],
  name: 'SharkTrade',
});

const Providers = compose(
  [ThemeProvider, { theme }],
  [SnackbarProvider],
  [TradingViewProvider],
  [WalletProvider],
  [OrderStrategyProvider],
  [OrderScalingProvider],
  [SingleOrderProvider]
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Providers>
      <CssBaseline />
      <App />
    </Providers>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.warn))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
