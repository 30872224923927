import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Grow from '@mui/material/Grow';

import { useWallet } from './Context';
import TextFieldNumber from '../TextFieldNumber';

export default function WalletCard() {
  const { isWalletLoaded, isWrongAccountSize, accountSize } = useWallet();

  return (
    <Grow in={isWalletLoaded} timeout="auto" unmountOnExit>
      <Card elevation={1}>
        <CardContent>
          <Box sx={{ mb: 1, display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="overline">Wallet</Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldNumber
                value={accountSize.value}
                label="Account Size"
                size="small"
                fullWidth
                error={isWrongAccountSize}
                thousandsSeparator=" "
                onValueChange={(value) => accountSize.mutate(value)}
                onFocusCapture={(e) => e.target.select()}
                inputProps={{ style: { textAlign: 'right' } }}
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grow>
  );
}
