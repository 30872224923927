import { divide, minus, plus, times, round } from 'number-precision';
import { nanoid } from 'nanoid';

export const KEY_ID = 'id';
export const KEY_PRICE = 'price';
export const KEY_SIZE = 'size';

export const calculateOrderScalingPrices = (orderQty, rangeStart, rangeEnd, opts = {}) => {
  const { precision = { price: 2, amount: 3 } } = opts;
  const diff = minus(rangeStart, rangeEnd);
  const orderPrice = divide(diff, orderQty - 1);
  const calculatePrice = (_, i) => round(minus(rangeStart, times(orderPrice, i)), precision.price) || 0;
  return Array.from({ length: orderQty }, calculatePrice);
};

export const calculateOrderScalingSizes = (orderQty, totalSize, scale, opts = {}) => {
  const { preventTrim = false, precision = { price: 2, amount: 3 } } = opts;
  const factors = Array.from({ length: orderQty }, (_, i) => Math.pow(plus(i, 1), scale));
  const factorTotal = factors.reduce((acc, cur) => plus(acc, cur), 0);

  // fix decimal rounding exceeds
  let restSize = totalSize;

  return factors.map((value, i) => {
    const factor = divide(value, factorTotal);
    const size = times(totalSize, factor);
    const sizeRound = precision.amount ? round(size, precision.amount) : size;
    restSize = minus(restSize, sizeRound);
    return (preventTrim ? size : plus(sizeRound, i + 1 === orderQty ? restSize : 0)) || 0;
  });
};

export const calculateOrderScaling = (rangeStart, rangeEnd, orderQty, totalSize, scale, opts = {}) => {
  const { precision = { price: 2, amount: 3 } } = opts;
  const prices = calculateOrderScalingPrices(orderQty, rangeStart, rangeEnd, opts);
  const sizes = calculateOrderScalingSizes(orderQty, totalSize, scale, opts);

  const orders = Array(orderQty).fill();
  return orders.map((_, i) => {
    return {
      [KEY_ID]: nanoid(),
      [KEY_PRICE]: prices[i].toFixed(precision.price),
      [KEY_SIZE]: sizes[i].toFixed(precision.amount),
    };
  });
};

export const leverageLevelColor = (leverage) => {
  let level = 'good';
  if (leverage >= 50) level = 'danger';
  else if (leverage >= 25) level = 'warning';
  return {
    good: 'success',
    warning: 'warning',
    danger: 'error',
  }[level];
};
