import { LineStyle } from 'lightweight-charts';

import * as calc from '../tools/calculations';
import { exchange } from '../chart/exchange';
import { sleep } from '../tools/polling';
import { trimSec } from '../tools/time';
import { minmove } from '../tools/number';
import { convertOhlcvTemp } from '../chart/datafeed';
import { calculateOrderScalingPrices, calculateOrderScalingSizes } from './order';

export const drawOrderScalingLines = (series, orderScaling, opts = {}) => {
  const { colors, precision = { amount: 2 } } = opts;
  const { upColor = 'green', downColor = 'red', executedColor = '#ad00ff' } = colors;
  const { orderQty, firstPrice, lastPrice, calculations, scale, takeProfit, stopLoss, executedOrders } = orderScaling;

  const priceLines = [];
  const optsCalc = { precision };
  const prices = calculateOrderScalingPrices(orderQty.value, firstPrice.value, lastPrice.value, optsCalc);
  const sizes = calculateOrderScalingSizes(orderQty.value, calculations.positionSize, scale.value, optsCalc);
  const executedOrdersSeries = [];

  prices.forEach((price, i) => {
    if (executedOrders.has(i)) {
      executedOrdersSeries.push(price, sizes[i]);
    }
    priceLines.push(
      series.createPriceLine({
        price,
        color: executedOrders.has(i) ? executedColor : '#ffffff',
        lineStyle: LineStyle.Dashed,
        lineWidth: 1,
        title: `${sizes[i].toFixed(precision.amount)} | #${i < 9 ? '0' : ''}${i + 1}`,
        axisLabelVisible: false,
      })
    );
  });

  if (executedOrdersSeries.length > 0) {
    priceLines.push(
      series.createPriceLine({
        price: calc.wavg(...executedOrdersSeries),
        color: executedColor,
        lineStyle: LineStyle.Solid,
        lineWidth: 1,
        title: 'Pos. AVG',
      })
    );
  }

  const takeProfitOpts = { price: takeProfit.value, color: upColor, lineStyle: LineStyle.Solid, title: 'TP' };
  const stopLossOpts = { price: stopLoss.value, color: downColor, lineStyle: LineStyle.Solid, title: 'SL' };
  priceLines.push(series.createPriceLine(takeProfitOpts));
  priceLines.push(series.createPriceLine(stopLossOpts));
  priceLines.push(
    series.createPriceLine({
      price: calculations.positionWeightedAvg,
      color: 'yellow',
      lineStyle: LineStyle.Dotted,
      lineWidth: 1,
      title: 'AVG',
    })
  );
  return priceLines;
};

export const createCandlestickSeries = (chart, opts = {}) => {
  const { colors, precision = { price: 2, amount: 3 } } = opts;
  const { upColor: up = 'green', downColor: down = 'red' } = colors;
  const candles = chart.addCandlestickSeries({
    upColor: up,
    downColor: down,
    borderUpColor: up,
    borderDownColor: down,
    wickUpColor: up,
    wickDownColor: down,
    priceFormat: { type: 'price', precision: precision.price, minMove: minmove(precision.price) },
  });
  const volume = chart.addHistogramSeries({
    priceFormat: { type: 'volume' },
    priceScaleId: '',
    priceLineVisible: false,
    lastValueVisible: false,
    priceScale: {},
  });
  volume.priceScale().applyOptions({
    scaleMargins: { top: 0.8, bottom: 0 },
  });
  return [candles, volume];
};

export const hydrateCandlestickSeries = async (ohlcv, candles, volume, opts = {}) => {
  const { colors } = opts;
  const { upColor: up = 'green', downColor: down = 'red' } = colors;

  // const ohlcv = await exchange.fetchOHLCV(symbol, resolution);
  const ohlcvConverted = ohlcv.map(convertOhlcvTemp);
  candles.setData(ohlcvConverted);
  volume.setData(
    ohlcvConverted.map(({ time, volume, close, open }) => ({
      time,
      value: volume,
      color: close >= open ? `${up}80` : `${down}80`,
    }))
  );
};

export const startCandlestickSeriesPolling = (candlestickSeries, volumeSeries, symbol, resolution, opts = {}) => {
  const { colors } = opts;
  const { upColor: up = 'green', downColor: down = 'red' } = colors;

  setTimeout(async () => {
    if (exchange.has.fetchOHLCV) {
      while (global.subscriptionActive) {
        const ohlcv = await exchange.fetchOHLCV(symbol, resolution, undefined, 1);
        const [ohlcvConverted] = ohlcv.map(convertOhlcvTemp);
        const { time, volume, open, close } = ohlcvConverted;
        const isPresentTime = time <= trimSec(new Date());
        const hasUpdate = candlestickSeries.update && volumeSeries.update;

        if (global.subscriptionActive && isPresentTime && hasUpdate) {
          candlestickSeries.update(ohlcvConverted);
          volumeSeries.update({ ...ohlcvConverted, value: volume, color: close >= open ? `${up}80` : `${down}80` });
          // console.log('polled');
          await sleep(exchange.rateLimit);
        }
      }
    }
  });
};
