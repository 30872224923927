import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    // mode: window.matchMedia("(prefers-color-scheme: dark)").matches ? 'dark' : 'light',
    // mode: 'light',
    mode: "dark",
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#131722",
    },
    background: {
      default: "#131722",
      paper: "#131722",
    },
    green: "#4cc9f0",
    red: "#f72585",
    success: {
      main: "#4cc9f0",
    },
    error: {
      main: "#f72585",
    },
    // Provide every color token (light, main, dark, and contrastText) when using
    // custom colors for props in Material UI's components.
    // Then you will be able to use it like this: `<Button color="custom">`
    // (For TypeScript, you need to add module augmentation for the `custom` value)
    custom: {
      light: "#ffa726",
      main: "#f57c00",
      dark: "#ef6c00",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow:
            "0px 10px 5px -5px rgba(0,0,0,0.2), 0px 5px 5px 0px rgba(0,0,0,0.14), 0px 5px 15px 0px rgba(0,0,0,0.12)",
        },
      },
    },
  },
});

export default theme;
