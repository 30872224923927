import { useState, useContext, createContext, useEffect } from 'react';
import localforage from 'localforage';

import { DEFAULT_ACCOUNT_SIZE } from '../../tools/defaults';
import { wrap } from '../../tools/context';

export const STORAGE_KEY = 'Wallet';
export const Wallet = createContext();
export const useWallet = () => useContext(Wallet);
export function WalletProvider({ children }) {
  const [isWalletLoaded, setWalletLoaded] = useState(false);
  const [accountSize, setAccountSize] = useState();
  const [isWrongAccountSize, setIsWrongAccountSize] = useState(false);

  useEffect(() => {
    // <<< LOAD >>>
    (async () => {
      const { accountSize } = (await localforage.getItem(STORAGE_KEY)) || {};
      setAccountSize(accountSize || DEFAULT_ACCOUNT_SIZE);
      setWalletLoaded(true);
    })();
  }, []);

  useEffect(() => {
    // <<< UPDATE >>>
    (async () => {
      await localforage.setItem(STORAGE_KEY, { accountSize });
    })();
  }, [accountSize]);

  useEffect(() => {
    if (isWalletLoaded) {
      setIsWrongAccountSize(accountSize <= 0);
    }
  }, [isWalletLoaded, accountSize]);

  const provider = {
    isWalletLoaded,
    isWrongAccountSize,
    accountSize: wrap(parseFloat(accountSize || 0), setAccountSize),
  };
  return <Wallet.Provider value={provider}>{children}</Wallet.Provider>;
}
export const withWallet = (children) => <WalletProvider>{children}</WalletProvider>;
