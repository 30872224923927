import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import TradingViewSymbols from './Symbols';
import TradingViewResolutions from './Resolutions';

export default function TradingViewControls() {
  const smallBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: smallBreakpoint ? 'row' : 'column',
        position: 'absolute',
        top: 0,
        left: 0,
        bgcolor: smallBreakpoint ? 'inherit' : '#0E1018',
        pt: (theme) => theme.spacing(2),
        px: (theme) => theme.spacing(2),
        zIndex: (theme) => theme.zIndex.drawer - 1,
        width: '100%',
        '& > *': { mr: smallBreakpoint ? 2 : 0, mb: 1 },
        '& > *:last-of-type': { mb: 0 },
      }}
    >
      <TradingViewSymbols />
      <TradingViewResolutions />
    </Box>
  );
}
