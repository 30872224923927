import { useState, useContext, createContext } from 'react';
import Box from '@mui/material/Box';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { green } from '@mui/material/colors';

import { copy } from '../tools/dom';
import { noop } from '../tools/void';
import ToggleIcon from './ToggleIcon';

const CopyFeedbackContext = createContext();
const useCopyFeedback = () => useContext(CopyFeedbackContext);

function CopyFeedback({ children, component: Component = Box, copyText, ...props }) {
  const [copied, setCopied] = useState(false);
  const provider = { copied, setCopied };
  const onClick = (...args) => {
    copy(copyText, () => setCopied(true));
    setTimeout(() => setCopied(false), 750);
    (props.onClick || noop)(...args);
  };
  return (
    <CopyFeedbackContext.Provider value={provider}>
      <Component {...props} onClick={onClick}>
        {children}
      </Component>
    </CopyFeedbackContext.Provider>
  );
}

function CopyFeedbackIcon({ placement, sx = {} }) {
  const { copied } = useCopyFeedback();
  return (
    <ToggleIcon
      on={copied}
      onIcon={<CheckCircleOutlineIcon sx={{ ...sx, color: green[500], fontSize: (sx.fontSize || 10) + 2 }} />}
      offIcon={<ContentCopyIcon sx={{ ...sx }} />}
      placement={placement}
    />
  );
}

CopyFeedback.Icon = CopyFeedbackIcon;
export default CopyFeedback;
export const withCopyFeedback = (children) => <CopyFeedback>{children}</CopyFeedback>;
