import React, { createContext, useContext, useState } from 'react';
import Button from '@mui/material/Button';
import MuiSnackbar from '@mui/material/Snackbar';
import MuiSnackbarContent from '@mui/material/SnackbarContent';
import { wrap } from '../tools/context';

const Snackbar = ({
  isSnackbarOpen,
  message,
  buttonOnClick,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  autoHideDuration = 7000,
}) => (
  <MuiSnackbar
    className="Snackbar"
    open={isSnackbarOpen}
    anchorOrigin={anchorOrigin}
    onClose={() => buttonOnClick()}
    autoHideDuration={autoHideDuration}
  >
    <MuiSnackbarContent
      message={<span className="four snackbar-message">{message}</span>}
      action={[
        <Button key="close" className="snackbar-button" onClick={buttonOnClick}>
          <span className="four close">Fechar</span>
        </Button>,
      ]}
    />
  </MuiSnackbar>
);

export const SnackbarContext = createContext(null);
export const SnackbarProvider = ({ children }) => {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [duration, setDuration] = useState(7000);

  const provider = {
    setSnackbarMessage,
    duration: wrap(duration, setDuration),
  };

  return (
    <SnackbarContext.Provider value={provider}>
      <Snackbar
        isSnackbarOpen={!!snackbarMessage}
        message={snackbarMessage}
        buttonOnClick={() => setSnackbarMessage('')}
        autoHideDuration={duration}
      />
      {children}
    </SnackbarContext.Provider>
  );
};
export const useSnackbar = () => useContext(SnackbarContext);

export default Snackbar;
