import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { createChart, ColorType, CrosshairMode } from 'lightweight-charts';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { exchange } from '../../chart/exchange';
import { usePrevious } from '../../tools/hooks';
import { useTradingView } from './Context';
import { useOrderScaling } from '../OrderScaling/Context';
import {
  createCandlestickSeries,
  drawOrderScalingLines,
  hydrateCandlestickSeries,
  startCandlestickSeriesPolling,
} from '../../tools/lwc';
import { exchangeResolutionMap } from '../../chart/datafeed';
import { isDiff, isExists } from '../../tools/verifiers';

global.subscriptionActive = false;

const COLOR_BACKGROUND = '#00000050';
const COLOR_TEXT = '#ffffff';
const COLOR_UP = '#1cfffa';
const COLOR_DOWN = '#ff005e';

export default function TradingViewLWC({ sx = {} }) {
  const smallBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('sm'));
  const orderScaling = useOrderScaling();
  const { orderQty, firstPrice, lastPrice, calculations, scale, stopLoss, takeProfit } = orderScaling;
  const { isReady, market, resolution } = useTradingView();

  const [loading, setLoading] = useState(true);

  const priceLineSeries = useRef([]);
  const prevMarket = usePrevious(market);
  const prevSymbol = usePrevious(market.value.symbol);
  const prevResolution = usePrevious(resolution);
  const prevOrderQty = usePrevious(orderQty);
  const prevFirstEntryPrice = usePrevious(firstPrice);
  const prevLastEntryPrice = usePrevious(lastPrice);
  const prevCalculations = usePrevious(calculations);
  const prevScale = usePrevious(scale);
  const prevStopLoss = usePrevious(stopLoss);
  const prevTakeProfit = usePrevious(takeProfit);
  const prevExecutedOrders = usePrevious(orderScaling.executedOrders);

  const chartContainerRef = useRef();
  const chart = useRef();
  const candlestickSeries = useRef();
  const volumeSeries = useRef();

  useEffect(() => {
    // <<< INITIALIZE CHART >>>
    if (isReady.value) {
      const newChart = createChart(chartContainerRef.current, {
        autoSize: true,
        crosshair: { mode: CrosshairMode.Normal },
        rightPriceScale: { autoScale: true },
        layout: {
          background: { type: ColorType.Solid, color: COLOR_BACKGROUND },
          textColor: COLOR_TEXT,
        },
        grid: {
          vertLines: { visible: false },
          horzLines: { visible: false },
        },
        timeScale: {
          rightOffset: 15,
          rightBarStaysOnScroll: true,
          timeVisible: true,
          secondsVisible: false,
        },
      });
      chart.current = newChart;

      const handleResize = () => {
        newChart.applyOptions({
          width: chartContainerRef.current.clientWidth,
          height: chartContainerRef.current.clientHeight,
        });
      };
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
        newChart.remove();
      };
    }
  }, [isReady.value]);

  useLayoutEffect(() => {
    if (chart.current) {
      chart.current.timeScale().fitContent();
    }
  }, [isReady.value]);

  useEffect(() => {
    // <<< SERIES >>>
    if (isReady.value) {
      (async () => {
        setLoading(true);
        global.subscriptionActive = false;

        const { symbol, precision } = market.value;
        const resolutionMapped = exchangeResolutionMap(resolution.value);
        const ohlcv = await exchange.fetchOHLCV(symbol, resolutionMapped);

        try {
          if (isExists(candlestickSeries.current)) chart.current.removeSeries(candlestickSeries.current);
          if (isExists(volumeSeries.current)) chart.current.removeSeries(volumeSeries.current, 'hello world');
        } catch (e) {}

        const colors = { upColor: COLOR_UP, downColor: COLOR_DOWN };
        const opts = { colors, precision };
        const [newCandles, newVolume] = createCandlestickSeries(chart.current, opts);
        const priceLines = drawOrderScalingLines(newCandles, orderScaling, opts);
        await hydrateCandlestickSeries(ohlcv, newCandles, newVolume, opts);

        startCandlestickSeriesPolling(newCandles, newVolume, symbol, resolutionMapped, opts);
        candlestickSeries.current = newCandles;
        volumeSeries.current = newVolume;
        priceLineSeries.current = priceLines;
        // TODO: add an option to toggle these options.
        // chart.current.timeScale().resetTimeScale();
        // chart.current.priceScale('right').applyOptions({ autoScale: true });

        global.subscriptionActive = true;
        setLoading(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady.value, market.value, resolution.value, prevMarket, prevResolution, prevSymbol]);

  useEffect(() => {
    // <<< PRICE LINES >>>
    const currValues = [
      orderQty?.value,
      firstPrice?.value,
      lastPrice?.value,
      calculations?.positionSize,
      calculations?.positionWeightedAvg,
      scale?.value,
      stopLoss?.value,
      takeProfit?.value,
      orderScaling.executedOrders.values(),
    ];
    const prevValues = [
      prevOrderQty?.value,
      prevFirstEntryPrice?.value,
      prevLastEntryPrice?.value,
      prevCalculations?.positionSize,
      prevCalculations?.positionWeightedAvg,
      prevScale?.value,
      prevStopLoss?.value,
      prevTakeProfit?.value,
      prevExecutedOrders?.values(),
    ];

    if (isExists(candlestickSeries.current) && isDiff(currValues, prevValues)) {
      const { precision } = market?.value;
      const colors = { upColor: COLOR_UP, downColor: COLOR_DOWN };
      const opts = { colors, precision };
      priceLineSeries.current.forEach((line) => candlestickSeries.current.removePriceLine(line));
      priceLineSeries.current = drawOrderScalingLines(candlestickSeries.current, orderScaling, opts);
    }
  }, [
    candlestickSeries,
    market?.value,
    prevExecutedOrders,
    prevCalculations?.positionSize,
    prevCalculations?.positionWeightedAvg,
    prevFirstEntryPrice?.value,
    prevLastEntryPrice?.value,
    prevOrderQty?.value,
    prevScale,
    prevStopLoss?.value,
    prevTakeProfit?.value,
    orderScaling,
    scale,
    orderScaling.executedOrders,
    calculations?.positionSize,
    calculations?.positionWeightedAvg,
    firstPrice?.value,
    lastPrice?.value,
    orderQty?.value,
    stopLoss?.value,
    takeProfit?.value,
  ]);

  return (
    <Box
      ref={chartContainerRef}
      sx={{
        height: (theme) => `calc(${smallBreakpoint ? '100vh' : '90vh'} - ${theme.spacing(11)})`,
        borderRadius: (theme) => theme.spacing(1),
        position: 'relative',
        overflow: 'hidden',
        ...sx,
      }}
    >
      <Backdrop
        open={loading}
        sx={{ position: 'absolute', color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
