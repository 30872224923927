import { divide, minus, plus, times } from 'number-precision';
import { LIQUIDATION_RATE } from './defaults';

export const avg = (...args) => {
  return divide(plus(...args), args.length);
};
export const wavg = (...args) => {
  if (args.length === 0 || args.length % 2 !== 0) {
    throw Error(`Arguments number should be even. wavg(${args.join(', ')})`);
  }
  const values = args.filter((_, i) => i % 2 === 0);
  const weights = args.filter((_, i) => i % 2 !== 0);
  const products = values.reduce((a, cur, i) => plus(a, times(cur, weights[i])), 0);
  return divide(products, plus(...weights));
};

export const riskedCapital = (accountSize, risk) => {
  return times(accountSize, divide(risk, 100));
};
export const positionSize = (entry, stopLoss, riskedCapital) => {
  return divide(riskedCapital, minus(entry, stopLoss));
};
export const margin = (entry, leverage, positionSize) => {
  return divide(times(positionSize, entry), leverage);
};
export const liquidation = (entry, leverage) => {
  return plus(times(entry, minus(1, divide(1, leverage))), times(entry, LIQUIDATION_RATE));
};
export const pnl = (entry, takeProfit, positionSize) => {
  return times(positionSize, minus(takeProfit, entry));
};
export const riskReward = (pnl, riskedCapital) => {
  return divide(pnl, riskedCapital);
};
export const roe = (pnl, margin) => {
  return times(divide(pnl, margin), 100);
};
export const changePercent = (entry, takeProfit) => {
  return times(minus(divide(takeProfit, entry), 1), 100);
};
