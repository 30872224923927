import { useState, useEffect, useContext, createContext } from 'react';
import localforage from 'localforage';

import * as calc from '../../tools/calculations';
import { DEFAULT_ORDER_SCALING } from '../../tools/defaults';
import { wrap } from '../../tools/context';
import { calculateOrderScalingPrices, calculateOrderScalingSizes } from '../../tools/order';
import { mergeIndexed } from '../../tools/array';
import { useTradingView } from '../TradingView/Context';
import { useWallet } from '../Wallet/Context';

const emptyCalculations = {
  riskedCapital: 0,
  margin: 0,
  roe: 0,
  liquidation: 0,
  pnl: 0,
  positionWeightedAvg: 0,
  positionSize: 0,
  riskReward: 0,
  changePercent: 0,
};
const DEFAULT_SHOW_CALCULATIONS = false;
const DEFAULT_SHOW_ORDERS = false;

export const STORAGE_KEY = 'OrderScaling';
export const OrderScaling = createContext();
export const useOrderScaling = () => useContext(OrderScaling);
export function OrderScalingProvider({ children }) {
  const { market } = useTradingView();
  const { isWalletLoaded, isWrongAccountSize, accountSize } = useWallet();

  const [isOrderScalingLoaded, setOrderScalingLoaded] = useState(false);
  const [isWrongRisk, setIsWrongRisk] = useState(false);
  const [isWrongStopLoss, setIsWrongStopLoss] = useState(false);
  const [isWrongLastPrice, setIsWrongPrices] = useState(false);
  const [isWrongLeverage, setIsWrongLeverage] = useState(false);
  const [showCalculations, setShowCalculations] = useState(DEFAULT_SHOW_CALCULATIONS);
  const [showOrders, setShowOrders] = useState(DEFAULT_SHOW_ORDERS);
  const [risk, setRisk] = useState(DEFAULT_ORDER_SCALING.RISK);
  const [orderQty, setOrderQty] = useState(DEFAULT_ORDER_SCALING.ORDER_QTY);
  const [type, setType] = useState(DEFAULT_ORDER_SCALING.TYPE);
  const [firstPrice, setFirstPrice] = useState(DEFAULT_ORDER_SCALING.FIRST_ENTRY_PRICE);
  const [lastPrice, setLastPrice] = useState(DEFAULT_ORDER_SCALING.LAST_ENTRY_PRICE);
  const [stopLoss, setStopLossPrice] = useState(DEFAULT_ORDER_SCALING.STOP_LOSS_PRICE);
  const [takeProfit, setTakeProfitPrice] = useState(DEFAULT_ORDER_SCALING.TAKE_PROFIT_PRICE);
  const [leverageColor, setLeverageColor] = useState(DEFAULT_ORDER_SCALING.LEVERAGE_COLOR);
  const [leverage, setLeverage] = useState(DEFAULT_ORDER_SCALING.LEVERAGE);
  const [scale, setScale] = useState(DEFAULT_ORDER_SCALING.SCALE);
  const [calculations, setCalculations] = useState(emptyCalculations);
  const [executedOrders, setExecutedOrders] = useState(new Set());

  // LOAD
  useEffect(() => {
    (async () => {
      const storage = (await localforage.getItem(STORAGE_KEY)) || {};
      setShowCalculations(storage.showCalculations || DEFAULT_SHOW_CALCULATIONS);
      setShowOrders(storage.showOrders || DEFAULT_SHOW_ORDERS);
      setRisk(storage.risk || DEFAULT_ORDER_SCALING.RISK);
      setOrderQty(storage.orderQty || DEFAULT_ORDER_SCALING.ORDER_QTY);
      setType(storage.type || DEFAULT_ORDER_SCALING.TYPE);
      setFirstPrice(storage.firstPrice || DEFAULT_ORDER_SCALING.FIRST_ENTRY_PRICE);
      setLastPrice(storage.lastPrice || DEFAULT_ORDER_SCALING.LAST_ENTRY_PRICE);
      setStopLossPrice(storage.stopLoss || DEFAULT_ORDER_SCALING.STOP_LOSS_PRICE);
      setTakeProfitPrice(storage.takeProfit || DEFAULT_ORDER_SCALING.TAKE_PROFIT_PRICE);
      setLeverageColor(storage.leverageColor || DEFAULT_ORDER_SCALING.LEVERAGE_COLOR);
      setLeverage(storage.leverage || DEFAULT_ORDER_SCALING.LEVERAGE);
      setScale(storage.scale || DEFAULT_ORDER_SCALING.SCALE);
      setCalculations(storage.calculations || {});
      setExecutedOrders(storage.executedOrders || new Set());
    })();
  }, []);

  // UPDATE
  useEffect(() => {
    (async () => {
      await localforage.setItem(STORAGE_KEY, {
        showCalculations,
        showOrders,
        risk,
        orderQty,
        type,
        firstPrice,
        lastPrice,
        stopLoss,
        takeProfit,
        leverageColor,
        leverage,
        scale,
        calculations,
        executedOrders,
      });
    })();
  }, [
    showCalculations,
    showOrders,
    risk,
    orderQty,
    type,
    firstPrice,
    lastPrice,
    stopLoss,
    takeProfit,
    leverageColor,
    leverage,
    scale,
    calculations,
    executedOrders,
  ]);

  useEffect(() => {
    if (isWalletLoaded) {
      const { precision } = market.value;
      const optsCalc = { precision };
      const linearAvg = calc.avg(firstPrice, lastPrice);
      const riskedCapital = calc.riskedCapital(accountSize.value, risk);
      const positionSize = calc.positionSize(linearAvg, stopLoss, riskedCapital);
      const positionWeightedAvg = calc.wavg(
        ...mergeIndexed(
          calculateOrderScalingPrices(orderQty, firstPrice, lastPrice, optsCalc),
          calculateOrderScalingSizes(orderQty, positionSize, scale, optsCalc)
        )
      );

      const margin = calc.margin(positionWeightedAvg, leverage, positionSize);
      const liquidation = calc.liquidation(positionWeightedAvg, leverage);
      const pnl = calc.pnl(positionWeightedAvg, takeProfit, positionSize);
      const riskReward = calc.riskReward(pnl, riskedCapital);
      const roe = calc.roe(pnl, margin);
      const changePercent = calc.changePercent(positionWeightedAvg, takeProfit);

      const financials = { riskedCapital, margin, roe, liquidation, pnl };
      const positions = { positionWeightedAvg, positionSize };
      const percents = { riskReward, changePercent };
      setCalculations({ ...financials, ...positions, ...percents });

      const isLong = type === 'long';
      const isWrongStopLossLong = stopLoss > lastPrice;
      const isWrongStopLossShort = stopLoss < lastPrice;
      const hasWrongStopLoss = isLong ? isWrongStopLossLong : isWrongStopLossShort;
      setIsWrongStopLoss(hasWrongStopLoss);

      const isIdenticalPrices = firstPrice === lastPrice;
      const isWrongPricesLong = firstPrice < lastPrice;
      const isWrongPricesShort = firstPrice > lastPrice;
      const isWrongPricesType = isLong ? isWrongPricesLong : isWrongPricesShort;
      const hasWrongPrices = isIdenticalPrices || isWrongPricesType;
      setIsWrongPrices(hasWrongPrices);
      setIsWrongRisk(risk === 0);
      setIsWrongLeverage(leverage === 0);
    }
  }, [
    isWalletLoaded,
    accountSize,
    type,
    firstPrice,
    lastPrice,
    leverage,
    market,
    orderQty,
    risk,
    scale,
    stopLoss,
    takeProfit,
  ]);

  useEffect(() => {
    if (!isOrderScalingLoaded && Object.keys(calculations).length > 0) {
      setOrderScalingLoaded(true);
    }
  }, [isOrderScalingLoaded, calculations]);

  const resetSettings = () => {
    setRisk(DEFAULT_ORDER_SCALING.RISK);
    setOrderQty(DEFAULT_ORDER_SCALING.ORDER_QTY);
    setType(DEFAULT_ORDER_SCALING.TYPE);
    setFirstPrice(DEFAULT_ORDER_SCALING.FIRST_ENTRY_PRICE);
    setLastPrice(DEFAULT_ORDER_SCALING.LAST_ENTRY_PRICE);
    setStopLossPrice(DEFAULT_ORDER_SCALING.STOP_LOSS_PRICE);
    setTakeProfitPrice(DEFAULT_ORDER_SCALING.TAKE_PROFIT_PRICE);
    setLeverageColor(DEFAULT_ORDER_SCALING.LEVERAGE_COLOR);
    setLeverage(DEFAULT_ORDER_SCALING.LEVERAGE);
    setScale(DEFAULT_ORDER_SCALING.SCALE);
  };

  const resetExecutedOrders = () => {
    setExecutedOrders(new Set());
  };

  const provider = {
    isOrderScalingLoaded,
    showCalculations: wrap(showCalculations, setShowCalculations),
    showOrders: wrap(showOrders, setShowOrders),
    risk: wrap(parseFloat(risk), setRisk),
    orderQty: wrap(parseFloat(orderQty), setOrderQty),
    type: wrap(type, setType),
    firstPrice: wrap(parseFloat(firstPrice), setFirstPrice),
    lastPrice: wrap(parseFloat(lastPrice), setLastPrice),
    stopLoss: wrap(parseFloat(stopLoss), setStopLossPrice),
    takeProfit: wrap(parseFloat(takeProfit), setTakeProfitPrice),
    leverageColor: wrap(leverageColor, setLeverageColor),
    leverage: wrap(parseFloat(leverage), setLeverage),
    scale: wrap(parseFloat(scale), setScale),
    calculations,
    isWrongRisk,
    isWrongStopLoss,
    isWrongLastPrice,
    isWrongLeverage,
    hasWrongValues: isWrongAccountSize || isWrongRisk || isWrongStopLoss || isWrongLastPrice || isWrongLeverage,
    resetSettings,
    ...{ executedOrders, setExecutedOrders, resetExecutedOrders },
  };
  return <OrderScaling.Provider value={provider}>{children}</OrderScaling.Provider>;
}

export const withOrderScaling = (children) => <OrderScalingProvider>{children}</OrderScalingProvider>;
