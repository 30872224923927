import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { useOrderScaling } from './Context';
import { useTradingView } from '../TradingView/Context';
import { useWallet } from '../Wallet/Context';
import CopyFeedback from '../CopyFeedback';

const fontProps = { fontFamily: 'Monospace', fontWeight: 900, fontSize: 16 };
const copyFeedbackIconProps = { placement: 'right', sx: { fontSize: 12, ml: 1 } };

function ErrorMessageWrapper({ children }) {
  const { hasWrongValues } = useOrderScaling();
  return (
    <>
      {hasWrongValues && <Typography {...fontProps}>!!!!!!!</Typography>}
      {!hasWrongValues && children}
    </>
  );
}

export default function OrderScalingCalculations() {
  const { market } = useTradingView();
  const { accountSize } = useWallet();
  const { hasWrongValues, calculations, stopLoss } = useOrderScaling();
  const { precision } = market.value;

  const formattedRiskedCapital = calculations.riskedCapital.toFixed(2);
  const formattedPositionSize = calculations.positionSize.toFixed(precision.amount);
  const formattedPositionWavg = calculations.positionWeightedAvg.toFixed(precision.price);
  const formattedMargin = calculations.margin.toFixed(2);
  const formattedLiquidation = calculations.liquidation.toFixed(precision.price);
  const formattedRiskReward = calculations.riskReward.toFixed(2);
  const formattedPnL = calculations.pnl.toFixed(2);
  const formattedROE = calculations.roe.toFixed(2);
  const formattedChangePercent = calculations.changePercent.toFixed(3);

  const color = (subcolor) => (hasWrongValues ? 'primary' : subcolor);

  return (
    <List dense>
      <CopyFeedback component={ListItemButton} copyText={formattedRiskedCapital} disabled={hasWrongValues}>
        <ListItemText primary="Risked Capital" />
        <ErrorMessageWrapper>
          <Typography {...fontProps}>
            ${formattedRiskedCapital}
            <CopyFeedback.Icon {...copyFeedbackIconProps} />
          </Typography>
        </ErrorMessageWrapper>
      </CopyFeedback>

      <CopyFeedback component={ListItemButton} copyText={formattedPositionSize} disabled={hasWrongValues}>
        <ListItemText primary="Position Size" />
        <ErrorMessageWrapper>
          <Typography {...fontProps}>
            {formattedPositionSize}
            <CopyFeedback.Icon {...copyFeedbackIconProps} />
          </Typography>
        </ErrorMessageWrapper>
      </CopyFeedback>

      <CopyFeedback component={ListItemButton} copyText={formattedPositionWavg} disabled={hasWrongValues}>
        <ListItemText primary="Position Weighted Avg." />
        <ErrorMessageWrapper>
          <Typography {...fontProps}>
            ${formattedPositionWavg}
            <CopyFeedback.Icon {...copyFeedbackIconProps} />
          </Typography>
        </ErrorMessageWrapper>
      </CopyFeedback>

      <CopyFeedback component={ListItemButton} copyText={formattedMargin} disabled={hasWrongValues}>
        <ListItemText primary="Margin" />
        <ErrorMessageWrapper>
          <Typography {...fontProps} color={color(calculations.margin > accountSize.value ? 'red' : 'main')}>
            ${formattedMargin}
            <CopyFeedback.Icon {...copyFeedbackIconProps} />
          </Typography>
        </ErrorMessageWrapper>
      </CopyFeedback>

      <CopyFeedback component={ListItemButton} copyText={formattedLiquidation} disabled={hasWrongValues}>
        <ListItemText primary="Liquidation" />
        <ErrorMessageWrapper>
          <Typography {...fontProps} color={color(calculations.liquidation > stopLoss.value ? 'red' : 'main')}>
            ${formattedLiquidation}
            <CopyFeedback.Icon {...copyFeedbackIconProps} />
          </Typography>
        </ErrorMessageWrapper>
      </CopyFeedback>

      <CopyFeedback component={ListItemButton} copyText={formattedRiskReward} disabled={hasWrongValues}>
        <ListItemText primary="Risk / Reward" />
        <ErrorMessageWrapper>
          <Typography {...fontProps} color={color(parseFloat(formattedRiskReward) >= 1.0 ? 'green' : 'red')}>
            {formattedRiskReward}
            <CopyFeedback.Icon {...copyFeedbackIconProps} />
          </Typography>
        </ErrorMessageWrapper>
      </CopyFeedback>

      <CopyFeedback component={ListItemButton} copyText={formattedPnL} disabled={hasWrongValues}>
        <ListItemText primary="Estimated PnL" />
        <ErrorMessageWrapper>
          <Typography {...fontProps} color={color(calculations.pnl > 0 ? 'green' : 'red')}>
            ${formattedPnL}
            <CopyFeedback.Icon {...copyFeedbackIconProps} />
          </Typography>
        </ErrorMessageWrapper>
      </CopyFeedback>

      <CopyFeedback component={ListItemButton} copyText={formattedROE} disabled={hasWrongValues}>
        <ListItemText primary="ROE" />
        <ErrorMessageWrapper>
          <Typography {...fontProps} color={color(calculations.roe > 0 ? 'green' : 'red')}>
            {formattedROE}%
            <CopyFeedback.Icon {...copyFeedbackIconProps} />
          </Typography>
        </ErrorMessageWrapper>
      </CopyFeedback>

      <CopyFeedback component={ListItemButton} copyText={formattedChangePercent} disabled={hasWrongValues}>
        <ListItemText primary="Percent Change" />
        <ErrorMessageWrapper>
          <Typography {...fontProps} color={color(calculations.changePercent > 0 ? 'green' : 'red')}>
            {formattedChangePercent}%
            <CopyFeedback.Icon {...copyFeedbackIconProps} />
          </Typography>
        </ErrorMessageWrapper>
      </CopyFeedback>
    </List>
  );
}
