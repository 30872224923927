import { useEffect, useLayoutEffect, useRef } from 'react';

export function useResizeObserver(callback) {
  const ref = useRef(null);

  useLayoutEffect(() => {
    const element = ref?.current;
    if (!element) return;

    const observer = new ResizeObserver((entries) => callback(element, entries[0]));
    observer.observe(element);
    return () => observer.disconnect();
  }, [callback, ref]);

  return ref;
}

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};
