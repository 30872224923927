import Box from '@mui/material/Box';
import { PanelResizeHandle } from 'react-resizable-panels';

export default function ResizeHandle({ id, onDoubleClick, sx = {} }) {
  return (
    <Box onDoubleClick={onDoubleClick} sx={{ display: 'flex', ...sx }}>
      <Box
        className="ResizeHandleOuter"
        component={PanelResizeHandle}
        id={id}
        sx={{
          outline: 'none',
          flex: (theme) => `0 0 ${theme.spacing()}`,
          justifyContent: 'stretch',
          alignItems: 'stretch',
          padding: 1.5,
          display: 'flex',
          cursor: 'ew-resize',
          touchAction: 'none',
          userSelect: 'none',
          position: 'relative',
        }}
      >
        <Box
          className="ResizeHandleInner"
          sx={{
            display: 'block',
            color: '#ffffff50', // change alpha `00` to show icon
            position: 'absolute',
            top: (theme) => `calc(50% - ${theme.spacing(0.75)})`,
            left: (theme) => `calc(50% - ${theme.spacing(0.75)})`,
            width: (theme) => theme.spacing(1.5),
            height: (theme) => theme.spacing(1.5),
            fill: 'currentColor',
            flex: (theme) => `0 0 ${theme.spacing(0.75)}`,
          }}
        >
          <svg className="Icon" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              // d="M8,18H11V15H2V13H22V15H13V18H16L12,22L8,18M12,2L8,6H11V9H2V11H22V9H13V6H16L12,2Z" // horizontal
              d="M18,16V13H15V22H13V2H15V11H18V8L22,12L18,16M2,12L6,16V13H9V22H11V2H9V11H6V8L2,12Z" // vertical
            />
          </svg>
        </Box>
      </Box>
    </Box>
  );
}
