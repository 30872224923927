import { cloneElement } from 'react';
import Zoom from '@mui/material/Zoom';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

function ToggleIcon(props) {
  const { offIcon, onIcon, on, placement } = props;
  const mergedSx = { ...(onIcon?.props?.sx || {}), position: 'absolute' };
  const onIconCloned = cloneElement(onIcon, { ...onIcon.props, sx: mergedSx });
  const containerSx = { position: 'relative', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' };
  return (
    <Box component="span">
      <Tooltip title="Copied!" open={on} placement={placement} arrow>
        <Box component="span" sx={containerSx}>
          <Zoom in={on} appear={false}>
            {onIconCloned}
          </Zoom>
          <Zoom in={!on} appear={false}>
            {offIcon}
          </Zoom>
        </Box>
      </Tooltip>
    </Box>
  );
}

export default ToggleIcon;
