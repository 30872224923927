import { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Grow from '@mui/material/Grow';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';

import { useOrderScaling } from './Context';
import OrderScalingForm from './Form';
import OrderScalingCalculations from './Calculations';
import OrderScalingCurve from './Curve';
import OrderScalingTable from './Table';

export default function OrderScalingCard() {
  const orderScalingContext = useOrderScaling();
  const { isOrderScalingLoaded } = orderScalingContext;
  const { isWrongRisk, isWrongStopLoss, isWrongLastPrice, isWrongLeverage, hasWrongValues } = orderScalingContext;
  const { showCalculations, showOrders, resetSettings } = orderScalingContext;

  const isFirstRender = useRef(true);
  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  const handleShowCalculations = () => showCalculations.mutate((v) => !v);
  const handleShowOrders = () => showOrders.mutate((v) => !v);

  const calculationErrorMessageText = {
    [isWrongStopLoss]: '⚠️ Wrong stop-loss',
    [isWrongLastPrice]: '⚠️ Wrong last order',
    [isWrongLeverage]: '⚠️ Wrong leverage',
    [isWrongRisk]: '⚠️ Wrong risk',
  }[true];
  const calculationErrorMessage = hasWrongValues && (
    <Typography variant="overline" sx={{ mr: 1, color: (theme) => theme.palette.warning.main }}>
      {calculationErrorMessageText}
    </Typography>
  );

  return (
    <Grow in={isOrderScalingLoaded} timeout="auto">
      <Card className="OrderScalingCard" elevation={1} sx={{ mb: 2 }}>
        <CardContent sx={{ '&:last-child': { pb: 2 } }}>
          <Box sx={{ mb: 1, display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="overline">Order Scaling</Typography>
            <Tooltip title="Reset Settings" placement="left">
              <IconButton size="small" onClick={resetSettings}>
                <RefreshIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>

          <OrderScalingForm />

          <List sx={{ mx: -2 }}>
            <Divider />
            <ListItemButton dense onClick={handleShowCalculations}>
              <ListItemText primary="Calculations" primaryTypographyProps={{ variant: 'overline' }} />
              {calculationErrorMessage}
              {showCalculations.value ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemButton>
            <Collapse in={isOrderScalingLoaded && showCalculations.value} unmountOnExit={isFirstRender.current}>
              {isOrderScalingLoaded && <OrderScalingCalculations />}
            </Collapse>
            <Divider />
            <ListItemButton dense onClick={handleShowOrders}>
              <ListItemText primary="Orders" primaryTypographyProps={{ variant: 'overline' }} />
              {calculationErrorMessage}
              {showOrders.value ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemButton>
            <Collapse in={isOrderScalingLoaded && showOrders.value} unmountOnExit={isFirstRender.current}>
              {isOrderScalingLoaded && <OrderScalingCurve />}
              {isOrderScalingLoaded && <OrderScalingTable sx={{ mb: 2 }} />}
            </Collapse>
            <Divider />
          </List>
        </CardContent>
      </Card>
    </Grow>
  );
}
