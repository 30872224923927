import {
  ORDER_STRATEGY_SINGLE_ORDER,
  ORDER_STRATEGY_ORDER_SCALING,
  ORDER_STRATEGY_DYNAMIC_ORDERS,
} from '../../tools/defaults';

import { useOrderStrategy } from './Context';
// import SingleOrderCard from './SingleOrderCard';
import OrderScalingCard from '../OrderScaling/Card';

export default function OrderStrategySwitcher() {
  const { isOrderStrategyLoaded, orderStrategy } = useOrderStrategy();
  const SelectedOrderStrategyCard = {
    // [ORDER_STRATEGY_SINGLE_ORDER]: SingleOrderCard,
    [ORDER_STRATEGY_SINGLE_ORDER]: OrderScalingCard,
    [ORDER_STRATEGY_ORDER_SCALING]: OrderScalingCard,
    [ORDER_STRATEGY_DYNAMIC_ORDERS]: OrderScalingCard,
  }[orderStrategy.value];

  return isOrderStrategyLoaded ? <SelectedOrderStrategyCard /> : null;
}
