import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { KEY_ID, KEY_PRICE, KEY_SIZE, calculateOrderScaling } from '../../tools/order';
import { useTradingView } from '../TradingView/Context';
import { useOrderScaling } from './Context';
import CopyFeedback from '../CopyFeedback';

export default function OrderScalingTable({ sx = {} }) {
  const tradingview = useTradingView();
  const orderScaling = useOrderScaling();

  const { market } = tradingview;
  const { hasWrongValues } = orderScaling;
  const { firstPrice, lastPrice, orderQty, calculations, scale } = orderScaling;
  const { executedOrders, setExecutedOrders } = orderScaling;

  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const { precision } = market.value;
    setOrders(
      calculateOrderScaling(
        firstPrice.value, //
        lastPrice.value,
        orderQty.value,
        calculations.positionSize,
        scale.value,
        { precision }
      )
    );
  }, [firstPrice.value, lastPrice.value, orderQty.value, calculations.positionSize, scale.value, market.value]);

  return (
    <Grid container sx={{ px: 2.5, pb: 1, mb: 1, ...sx }}>
      {orders.map((order, i) => {
        const copyFeedbackIconProps = { placement: 'right', sx: { fontSize: 12, ml: 1 } };
        const sizeButtonColor = parseFloat(order[KEY_SIZE]) === 0 ? 'error' : 'primary';
        return (
          <Grid key={order[KEY_ID]} item xs={12}>
            <Grid container>
              <Grid item xs={2}>
                <Typography variant="caption">#{i + 1}</Typography>
              </Grid>
              <Grid item xs={4} sx={{ textAlign: 'center' }}>
                <CopyFeedback component={Button} copyText={order[KEY_PRICE]} size="small" fullWidth>
                  $ {order[KEY_PRICE]} <CopyFeedback.Icon {...copyFeedbackIconProps} />
                </CopyFeedback>
              </Grid>
              <Grid item xs={4} sx={{ textAlign: 'center' }}>
                <CopyFeedback
                  component={Button}
                  copyText={order[KEY_SIZE]}
                  color={sizeButtonColor}
                  size="small"
                  fullWidth
                  sx={{ ml: 2 }}
                  disabled={hasWrongValues}
                >
                  {hasWrongValues && '!!!!!!!'}
                  {!hasWrongValues && (
                    <>
                      {order[KEY_SIZE]} <CopyFeedback.Icon {...copyFeedbackIconProps} />
                    </>
                  )}
                </CopyFeedback>
              </Grid>
              <Grid item xs={2} sx={{ textAlign: 'right' }}>
                <Tooltip title="Mark as Executed" placement="right">
                  <Checkbox
                    size="small"
                    checked={executedOrders.has(i)}
                    onClick={() => {
                      const executions = new Set(executedOrders);
                      executions[executions.has(i) ? 'delete' : 'add'](i);
                      setExecutedOrders(executions);
                    }}
                    sx={{ ml: 2, p: 0 }}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}
