import { useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import DonationCard from './Card';

const createDonationMethod = (method) => method;
const DONATION_METHODS = [
  createDonationMethod({
    title: 'Binance Pay',
    description: 'Scan via the Binance App to send.',
    footerButtonLabel: 'My Pay ID: 229043626',
    footerButtonCopy: '229043626',
    qrcode: '/qrcode/binance-pay.png',
  }),
  // createDonationMethod({
  //   title: 'Brazilian Pix',
  //   description: 'Scan using your Bank App to send.',
  //   externalLink: 'My Pix Key: 229043626',
  //   qrcode: '/qrcode/pix.png',
  // }),
  // createDonationMethod({
  //   title: 'Others',
  //   description: "Can't donate with available methods?",
  //   externalLink: 'Request another donation method.',
  //   qrcode: '/qrcode/request.png',
  // }),
  createDonationMethod({
    title: '🚧',
    description: 'More donation methods will be added.',
    footer: 'Soon...',
    qrcode: '/qrcode/soon.png',
    disabled: true,
  }),
];

export default function DonationBar({ sx = {} }) {
  const [open, setOpen] = useState(false);
  const toggle = (v) => () => setOpen(v);

  return (
    <>
      <Alert
        className="DonationCTA"
        variant="filled"
        color="secondary"
        sx={{ alignItems: 'center', ...sx }}
        icon={<FavoriteIcon sx={{ color: (theme) => theme.palette.red }} />}
        action={
          <Button color="inherit" variant="contained" size="small" onClick={toggle(true)} sx={{ mr: 1 }}>
            Donate
          </Button>
        }
      >
        Help me keep this app up and running.
      </Alert>
      <Drawer
        className="DonationDrawer"
        anchor="bottom"
        open={open}
        onClose={toggle(false)}
        transitionDuration={750}
        ModalProps={{ keepMounted: true }}
        sx={{
          '& > .MuiPaper-root': { height: '90%', py: 3, px: 6, backgroundImage: 'none' },
          '.MuiCard-root': { height: '100%' },
        }}
      >
        <Typography variant="h5" gutterBottom>
          Your donation is much appreciated!
        </Typography>
        <Typography>
          This non-profit app aims to make the cryptocurrency trade plan easier for all investors.
        </Typography>
        <Typography>If you enjoy the app, you can help me keep this project up and running.</Typography>

        <Grid container spacing={3} sx={{ mt: 2, mb: 8, justifyContent: 'center' }}>
          {DONATION_METHODS.map((method) => (
            <Grid key={method.title} item sx={{ minWidth: 300 }} xs={12} sm={3}>
              <DonationCard {...method} />
            </Grid>
          ))}
        </Grid>

        <Typography sx={{ textAlign: 'center' }}>
          Made with{' '}
          <Typography component="span" color="red">
            ♥
          </Typography>{' '}
          by{' '}
          <Typography
            component="a"
            fontFamily="monospace"
            href="https://github.com/gabrielrtakeda"
            color="white"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ textDecoration: 'none' }}
          >
            @tk
          </Typography>
        </Typography>
      </Drawer>
    </>
  );
}
