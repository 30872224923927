import { useState, useEffect, useContext, createContext } from 'react';
import localforage from 'localforage';

import { wrap } from '../../tools/context';
import { DEFAULT_ORDER_STRATEGY } from '../../tools/defaults';

export const STORAGE_KEY = 'OrderStrategy';
export const OrderStrategy = createContext();
export const useOrderStrategy = () => useContext(OrderStrategy);
export function OrderStrategyProvider({ children }) {
  const [isOrderStrategyLoaded, setOrderStrategyLoaded] = useState(false);
  const [orderStrategy, setOrderStrategy] = useState();

  useEffect(() => {
    // <<< LOAD >>>
    (async () => {
      const { orderStrategy } = (await localforage.getItem(STORAGE_KEY)) || {};
      setOrderStrategy(orderStrategy || DEFAULT_ORDER_STRATEGY);
      setOrderStrategyLoaded(true);
    })();
  }, []);

  // <<< UPDATE >>>
  useEffect(() => {
    (async () => {
      await localforage.setItem(STORAGE_KEY, { orderStrategy });
    })();
  }, [orderStrategy]);

  const provider = {
    isOrderStrategyLoaded,
    orderStrategy: wrap(orderStrategy, setOrderStrategy),
  };
  return <OrderStrategy.Provider value={provider}>{children}</OrderStrategy.Provider>;
}
export const withOrderStrategy = (children) => <OrderStrategyProvider>{children}</OrderStrategyProvider>;
