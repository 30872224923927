import useMediaQuery from '@mui/material/useMediaQuery';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { SUPPORTED_RESOLUTIONS } from '../../tools/defaults';
import { supportedResolutionMap } from '../../chart/datafeed';
import { useTradingView } from './Context';

export default function TradingViewResolutions() {
  const { resolution } = useTradingView();

  const smallBreakpoint = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <ToggleButtonGroup
      value={resolution.value}
      exclusive
      onChange={(_, value) => value !== null && resolution.mutate(value)}
      sx={{ bgcolor: '#0E1018', height: (theme) => theme.spacing(5) }}
    >
      {SUPPORTED_RESOLUTIONS.map((resolution) => (
        <ToggleButton
          key={resolution}
          value={resolution}
          sx={{ p: 0, textTransform: 'none', width: (theme) => smallBreakpoint ? theme.spacing(6) : '100%' }}
        >
          {supportedResolutionMap(resolution)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
