import { useState, useEffect } from 'react';
import { divide, times } from 'number-precision';
import { NumberFormatBase } from 'react-number-format';
import TextField from '@mui/material/TextField';

import { noop } from '../tools/void';
import { divisor, minmove } from '../tools/number';
import { usePrevious } from '../tools/hooks';
import { neq } from '../tools/functional';

const formatter = (value, precision = 2) => {
  return new Intl.NumberFormat('en', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
    roundingPriority: 'lessPrecision',
  }).format(value);
};

export default function TextFieldNumber({
  value: initialValue,
  max = undefined,
  component = TextField,
  precision = 2,
  thousandsSeparator = ',',
  decimalSeparator = '.',
  onValueChange = noop,
  ...props
}) {
  const [value, setValue] = useState(times(initialValue, divisor(precision)));
  const prevValue = usePrevious(initialValue);

  useEffect(() => {
    if ([initialValue, value].every(neq(prevValue))) {
      setValue(times(initialValue, divisor(precision)));
    }
  }, [initialValue, precision, prevValue, value]);

  const limitMax = (v) => (max === undefined ? v : v > max ? max : v);

  return (
    <NumberFormatBase
      {...props}
      customInput={component}
      value={value}
      type="text"
      format={(v) =>
        `${formatter(divide(limitMax(v), divisor(precision)), precision)}`
          .replace(/,/g, thousandsSeparator)
          .replace(/\./g, decimalSeparator)
      }
      onValueChange={({ floatValue }) => {
        const value = Number(limitMax(floatValue));
        setValue(value);
        onValueChange(divide(value, divisor(precision)));
      }}
      onDoubleClick={(e) => e.target.select()}
      onKeyDown={(e) => {
        const { value } = e.target;

        const isntMetaKeys = [e.metaKey, e.altKey, e.ctrlKey].every((v) => v === false);
        const isntNumber = /^[A-Za-z\s!@#$%^&*()_+=-`~\\\][{}|';:/.,?><]$/.test(e.nativeEvent.key);
        if (isntMetaKeys && isntNumber) {
          return e.preventDefault();
        }

        const tick = minmove(precision) || 1;
        const isDeletingLastChar = e.key === 'Backspace' && Number(value) <= times(tick, 10);
        const isDeletingMultiChar = (e.metaKey || e.altKey || e.ctrlKey) && e.key === 'Backspace';
        const isDeletingSelectedTxt = document.getSelection().toString() === value && e.key === 'Backspace';
        const shouldReset = isDeletingLastChar || isDeletingMultiChar || isDeletingSelectedTxt;

        if (shouldReset) {
          e.preventDefault();
          setTimeout(() => {
            e.target.selectionStart = `${tick}`.length + 1;
            e.target.selectionEnd = `${tick}`.length + 1;
          }, 0);
          setValue(0);
        }
      }}
    />
  );
}
