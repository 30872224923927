export const MARKET_BTC = {
  id: 'BTCUSDT',
  lowercaseId: 'btcusdt',
  symbol: 'BTC/USDT:USDT',
  base: 'BTC',
  quote: 'USDT',
  settle: 'USDT',
  baseId: 'BTC',
  quoteId: 'USDT',
  settleId: 'USDT',
  type: 'swap',
  spot: false,
  margin: false,
  swap: true,
  future: false,
  option: false,
  active: true,
  contract: true,
  linear: true,
  inverse: false,
  taker: 0.0004,
  maker: 0.0002,
  contractSize: 1,
  precision: { amount: 3, price: 1, base: 8, quote: 8 },
  limits: {
    leverage: {},
    amount: { min: 0.001, max: 1000 },
    price: { min: 556.8, max: 4529764 },
    cost: { min: 5 },
    market: { min: 0.001, max: 120 },
  },
  info: {
    symbol: 'BTCUSDT',
    pair: 'BTCUSDT',
    contractType: 'PERPETUAL',
    deliveryDate: '4133404800000',
    onboardDate: '1569398400000',
    status: 'TRADING',
    maintMarginPercent: '2.5000',
    requiredMarginPercent: '5.0000',
    baseAsset: 'BTC',
    quoteAsset: 'USDT',
    marginAsset: 'USDT',
    pricePrecision: '2',
    quantityPrecision: '3',
    baseAssetPrecision: '8',
    quotePrecision: '8',
    underlyingType: 'COIN',
    underlyingSubType: ['PoW'],
    settlePlan: '0',
    triggerProtect: '0.0500',
    liquidationFee: '0.012500',
    marketTakeBound: '0.05',
    maxMoveOrderLimit: '10000',
    filters: [
      { minPrice: '556.80', maxPrice: '4529764', filterType: 'PRICE_FILTER', tickSize: '0.10' },
      { stepSize: '0.001', filterType: 'LOT_SIZE', maxQty: '1000', minQty: '0.001' },
      { stepSize: '0.001', filterType: 'MARKET_LOT_SIZE', maxQty: '120', minQty: '0.001' },
      { limit: '200', filterType: 'MAX_NUM_ORDERS' },
      { limit: '10', filterType: 'MAX_NUM_ALGO_ORDERS' },
      { notional: '5.0', filterType: 'MIN_NOTIONAL' },
      { multiplierDown: '0.9500', multiplierUp: '1.0500', multiplierDecimal: '4', filterType: 'PERCENT_PRICE' },
    ],
    orderTypes: ['LIMIT', 'MARKET', 'STOP', 'STOP_MARKET', 'TAKE_PROFIT', 'TAKE_PROFIT_MARKET', 'TRAILING_STOP_MARKET'],
    timeInForce: ['GTC', 'IOC', 'FOK', 'GTX'],
  },
};
