import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Grow from '@mui/material/Grow';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { ORDER_STRATEGY_LIST, DEFAULT_ORDER_STRATEGY } from '../../tools/defaults';
import { useOrderStrategy } from './Context';

export default function OrderStrategyCard() {
  const { isOrderStrategyLoaded, orderStrategy } = useOrderStrategy();

  return (
    <Grow in={isOrderStrategyLoaded} timeout="auto">
      <Card className="OrderStrategyCard" elevation={1}>
        <CardContent>
          <Box sx={{ mb: 1, display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="overline">Strategy</Typography>
            <Tooltip title="Click to see strategy details." placement="left">
              <IconButton size="small">
                <HelpOutlineIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                select
                fullWidth
                value={orderStrategy.value || DEFAULT_ORDER_STRATEGY}
                size="small"
                label="Type"
                onChange={({ target: { value } }) => orderStrategy.mutate(value)}
              >
                {ORDER_STRATEGY_LIST.map((option) => (
                  <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grow>
  );
}
