import { divide } from 'number-precision';
import { MARKET_BTC } from './tradingview';

export const DEFAULT_MARKET = MARKET_BTC;
export const DEFAULT_RESOLUTION = '1D';

export const SUPPORTED_RESOLUTIONS = ['1', '5', '15', '60', '240', '1D', '1W', '1M'];

export const LIQUIDATION_RATE = divide(1.1, 100);
export const DEFAULT_MIN_LEFT_PANEL = 30;
export const DEFAULT_MIN_RIGHT_PANEL = 40;
export const DEFAULT_ACCOUNT_SIZE = 25000;
export const DEFAULT_SCALE = 1.0;
export const DEFAULT_SCALE_MIN = 0;
export const DEFAULT_SCALE_MAX = 2;
export const DEFAULT_SCALE_STEP = 0.05;

export const ORDER_STRATEGY_SINGLE_ORDER = 'single-order';
export const ORDER_STRATEGY_ORDER_SCALING = 'order-scaling';
export const ORDER_STRATEGY_DYNAMIC_ORDERS = 'dynamic-orders';
export const ORDER_STRATEGY_LIST = [
  { value: ORDER_STRATEGY_ORDER_SCALING, label: 'Order Scaling', disabled: false },
  { value: ORDER_STRATEGY_SINGLE_ORDER, label: '(soon) Single Order', disabled: true },
  { value: ORDER_STRATEGY_DYNAMIC_ORDERS, label: '(soon) Dynamic Orders', disabled: true },
];
export const DEFAULT_ORDER_STRATEGY = ORDER_STRATEGY_ORDER_SCALING;

export const DEFAULT_SINGLE_ORDER = {
  RISK: 1,
  TYPE: 'long',
  STOP_LOSS_PRICE: 23000,
  TAKE_PROFIT_PRICE: 29000,
  LEVERAGE_COLOR: 'success',
  LEVERAGE: 1,
  ENTRY_PRICE: 25000,
};

export const DEFAULT_ORDER_SCALING = {
  RISK: 1,
  TYPE: 'long',
  STOP_LOSS_PRICE: 19999,
  TAKE_PROFIT_PRICE: 31000,
  LEVERAGE_COLOR: 'success',
  LEVERAGE: 1,
  SCALE: 1.0,
  ORDER_QTY: 2,
  FIRST_ENTRY_PRICE: 26000,
  LAST_ENTRY_PRICE: 24000,
};
