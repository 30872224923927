import { useState, useContext, createContext } from 'react';

import * as calc from '../../tools/calculations';
import { DEFAULT_SINGLE_ORDER } from '../../tools/defaults';
import { wrap } from '../../tools/context';
import { useWallet } from '../Wallet/Context';

export const SingleOrder = createContext();
export const useSingleOrder = () => useContext(SingleOrder);
export function SingleOrderProvider({ children }) {
  const { accountSize } = useWallet();

  const [risk, setRisk] = useState(DEFAULT_SINGLE_ORDER.RISK);
  const [orderQty, setOrderQty] = useState(DEFAULT_SINGLE_ORDER.ORDER_QTY);
  const [type, setType] = useState(DEFAULT_SINGLE_ORDER.TYPE);
  const [entry, setEntryPrice] = useState(DEFAULT_SINGLE_ORDER.ENTRY_PRICE);
  const [stopLoss, setStopLossPrice] = useState(DEFAULT_SINGLE_ORDER.STOP_LOSS_PRICE);
  const [takeProfit, setTakeProfitPrice] = useState(DEFAULT_SINGLE_ORDER.TAKE_PROFIT_PRICE);
  const [leverageColor, setLeverageColor] = useState(DEFAULT_SINGLE_ORDER.LEVERAGE_COLOR);
  const [leverage, setLeverage] = useState(DEFAULT_SINGLE_ORDER.LEVERAGE);

  const riskedCapital = calc.riskedCapital(accountSize.value, risk);
  const positionSize = calc.positionSize(entry, stopLoss, riskedCapital);
  const margin = calc.margin(entry, leverage, positionSize);
  const liquidation = calc.liquidation(entry, leverage);
  const pnl = calc.pnl(entry, takeProfit, positionSize);
  const riskReward = calc.riskReward(pnl, riskedCapital);
  const roe = calc.roe(pnl, margin);
  const changePercent = calc.changePercent(entry, takeProfit);

  const financials = { riskedCapital, margin, roe, liquidation, pnl };
  const positions = { positionSize };
  const percents = { riskReward, changePercent };
  const calculations = { ...financials, ...positions, ...percents };

  const resetSettings = () => {
    setRisk(DEFAULT_SINGLE_ORDER.RISK);
    setOrderQty(DEFAULT_SINGLE_ORDER.ORDER_QTY);
    setType(DEFAULT_SINGLE_ORDER.TYPE);
    setEntryPrice(DEFAULT_SINGLE_ORDER.ENTRY_PRICE);
    setLeverageColor(DEFAULT_SINGLE_ORDER.LEVERAGE_COLOR);
    setLeverage(DEFAULT_SINGLE_ORDER.LEVERAGE);
  };

  const provider = {
    risk: wrap(risk, setRisk),
    orderQty: wrap(orderQty, setOrderQty),
    type: wrap(type, setType),
    entry: wrap(entry, setEntryPrice),
    stopLoss: wrap(stopLoss, setStopLossPrice),
    takeProfit: wrap(takeProfit, setTakeProfitPrice),
    leverageColor: wrap(leverageColor, setLeverageColor),
    leverage: wrap(leverage, setLeverage),
    calculations,
    resetSettings,
  };
  return <SingleOrder.Provider value={provider}>{children}</SingleOrder.Provider>;
}

export const withSingleOrder = (children) => <SingleOrderProvider>{children}</SingleOrderProvider>;
