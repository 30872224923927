import { binance as Exchange } from 'ccxt';

export const exchange = new Exchange({
  // apiKey: process.env.REACT_APP_BINANCE_API_KEY,
  // secret: process.env.REACT_APP_BINANCE,
  // enableRateLimit: true,
  options: {
    defaultType: 'future',
  },
});
